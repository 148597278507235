<template>
    <div>
        <app-top-bar title="Upcoming"></app-top-bar>
        <van-pull-refresh pulling-text="Pull to refresh" loosing-text="Release to refresh" loading-text="Loading..." @refresh="refresh" v-model="refreshing">
            <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
            </v-layout>
            <v-layout align-center justify-center :style="getStyle()" v-if="!loading && items.length === 0">You do not have any work yet</v-layout>
            <div :style="getStyle()" v-if="!loading && items.length > 0">
                <div :key="index" v-for="(item, index) in items">
                    <p class="px-4 pt-2 pb-1 mb-0 text-h6" v-text="item.date"></p>
                    <v-card class="elevation-0 rounded-0 mb-2" :ripple="false" :key="sindex" v-for="(shift, sindex) in item.shifts" @click="go(shift)">
                        <v-card-text class="py-0">
                            <v-list class="transparent">
                                <v-list-item>
                                    <v-list-item-title>Project Name</v-list-item-title>
                                    <v-list-item-subtitle class="text-right" v-text="shift.project"></v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>Working Time</v-list-item-title>
                                    <v-list-item-subtitle class="text-right" v-text="shift.working_time"></v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>Outlet Name</v-list-item-title>
                                    <v-list-item-subtitle class="text-right" v-text="shift.outlet"></v-list-item-subtitle>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                        <v-divider></v-divider>
                    </v-card>
                </div>
            </div>
        </van-pull-refresh>
        <app-bottom-bar :active-tab="1"></app-bottom-bar>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'
import { redirectNameTo } from '@/utils/route'
import AppTopBar from '../layout/AppTopBar.vue'
import AppBottomBar from '../layout/AppBottomBar.vue'

export default {
    name: 'UpcomingShiftList',
    components: {
        appTopBar: AppTopBar,
        appBottomBar: AppBottomBar,
    },
    data() {
        return {
            loading: true,
            refreshing: false,
            filters: {
                category: 'upcoming',
                month: ''
            },
            items: []
        }
    },
    mounted: function(){
        this.get()
    },
    methods: {
        get: function() {
			this.$store.dispatch('mobile/shift/getShifts', this.filters).then((response) => {
                this.loading = false
                this.items = response.data.items
            }).catch(() => {
                this.loading = false
            })
		},
		refresh() {
            delay(() => {
                this.get()
                this.refreshing = false
            }, 1000)
        },
        getStyle: function() {
            return {"min-height": (window.innerHeight - 56).toString() + "px"}
        },
        go: function(item) {
            redirectNameTo(this.$router, 'mobile-upcoming-shift', { code: item.code })
        }
    }
}

</script>


<style scoped>

/deep/ .v-list-item {
    padding: 0px !important;
    min-height: 36px !important;
}

</style>